import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs';
import { Globals } from '../../global';

@Injectable()
export class RevenueForecastAPIService {

	private url1 = this.globals.apiServerIP +'/api/getFRInputData';
	private url3 = this.globals.apiServerIP +'/api/getFRAccSows';
	private url4 = this.globals.apiServerIP +'/api/addFRAccount';
	private url5 = this.globals.apiServerIP +'/api/addFRSow';
	private url6 = this.globals.apiServerIP +'/api/addFRInputData';
	private url8 = this.globals.apiServerIP +'/api/updateFRInputData';
	private url9 = this.globals.apiServerIP +'/api/deleteFRInputData';
	private url10 = this.globals.apiServerIP +'/api/getForecastedSummary';
	private url11 = this.globals.apiServerIP +'/api/deleteFRInputDataEntries';
	private url12 = this.globals.apiServerIP +'/api/getForecastedAutomationReport';
	private url13 = this.globals.apiServerIP +'/api/getBackupDate';
	private url14 = this.globals.apiServerIP +'/api/getBackupReport';
	private url15 = this.globals.apiServerIP +'/api/getCurrencyName';

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
	){}

	/* url1 */
	getFRInputData(reqObj : any) {
		let params = new HttpParams();
		for(let key in reqObj) {
			params = params.append(key, reqObj[key]);
		}
		return this.httpClient.get(this.url1,{params: params})
		.pipe(
			map((response:any) => {return response[0].data;}),
			catchError(this.handleError)
		)
	};

	/* url3 */
	getFRAccSows() {
		return this.httpClient.get(this.url3)
		.pipe(
			map((response:any) => {return response[0].data;}),
			catchError(this.handleError)
		)
	};

	/* url4 */
	addFRAccount(model: {}) {
		return this.httpClient.post(this.url4,model)
		.pipe(
			map((response:any) => {return response;}),
			catchError(this.handleError)
		)
	}

	/* url5 */
	addFRSow(model: {}) {
		return this.httpClient.post(this.url5,model)
		.pipe(
			map((response:any) => {return response;}),
			catchError(this.handleError)
		)
	}

	/* url6 */
	addFRInputData(model: {}) {
		return this.httpClient.post(this.url6,model)
		.pipe(
			map((response:any) => {return response;}),
			catchError(this.handleError)
		)
	}

	/* url8 */
	updateFRInputData(model: {}) {
		return this.httpClient.put(this.url8,model)
		.pipe(
			map((response:any) => {return response;}),
			catchError(this.handleError)
		)
	}

	/* url9 */
	deleteFRInputData(model: {}) {
		return this.httpClient.put(this.url9,model)
		.pipe(
			map((response:any) => {return response;}),
			catchError(this.handleError)
		)
	}

	/* url10 */
	getForecastedSummary(reqObj : any) {

		let params = new HttpParams();
		params = params.append('start_date', reqObj.start_date);
		params = params.append('end_date', reqObj.end_date);
		params = params.append('as_on_date', reqObj.as_on_date);
		params = params.append('revList' , reqObj.revList);
		params = params.append('pursuedList' , reqObj.pursuedList);
		params = params.append('geographyList', reqObj.geographyList);
		params = params.append('probability', reqObj.probability);
		params = params.append('salesStageList',reqObj.salesStageList);
		return this.httpClient.get(this.url10,{params: params})
		.pipe(
			map((response:any) => {return response[0].data;}),
			catchError(this.handleError)
		)

	};

	/* url11 */
	deleteFRInputDataEntries(idList : any) {
		return this.httpClient.put(this.url11,idList)
		.pipe(
			map((response:any) => {return response;}),
			catchError(this.handleError)
		)
	}

	 /* url12 */
	getAutomationReport(reqObj: any) {
		let params = new HttpParams();
		params = params.append('start_date', reqObj.start_date);
		params = params.append('end_date', reqObj.end_date);
		params = params.append('as_on_date', reqObj.as_on_date);
		params = params.append('geography', reqObj.geographyList);
		params = params.append('probability', reqObj.probability);
		params = params.append('selectedAccs', reqObj.selectedAccsList);
		params = params.append('backup', reqObj.backup);
		params = params.append('salesStageList', reqObj.salesStageList);

		return this.httpClient.get(this.url12,{params: params})
			.pipe(
				map((response: any) => {return response[0].data;}),
				catchError(this.handleError)
			)

	};

	/* url13*/
	getBackupDate() {
		return this.httpClient.get(this.url13)
		.pipe(
			map((response:any) => {return response[0].data;}),
			catchError(this.handleError)
		)
	};

	 /* url14*/
	 getBackupReport(reqObj: any) {
		let params = new HttpParams();
		params = params.append('start_date', reqObj.start_date);
		params = params.append('end_date', reqObj.end_date);
		params = params.append('as_on_date', reqObj.as_on_date);
		params = params.append('geography', reqObj.geographyList);
		params = params.append('probability', reqObj.probability);
		params = params.append('selectedAccs', reqObj.selectedAccsList);

		return this.httpClient.get(this.url14,{params: params})
		.pipe(
			map((response:any) => {return response[0].data;}),
			catchError(this.handleError)
		)
	};

	getCurrency() {
		return this.httpClient.get(this.url15)
		.pipe(
			map((response:any) => {
			return response[0].data;
		}),
			catchError(this.handleError)
		)
	};

	private handleError(error: any): Promise<any> {
		console.error('An error in revenue forecast api service.', error);
		return Promise.reject(error.message || error);
	}

}
